import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { actionTypes, ErrorInputType } from '../../../modules/types/constant'
import { Nullable } from '../../../modules/types/core'
import { FormGroup } from '../../../modules/types/customizationPart'
import { EnvironmentConfiguration, ModelProperty } from '../../../modules/types/modelProperty'
import { Translation } from '../../../modules/types/translation'
import { getErrorInputMessage } from '../../../modules/utils/errorHandler.util'

import OutlinedButton from '../../atoms/Button/OutlinedButton'
import FormField from '../../atoms/FormField'
import Input from '../../atoms/Input'
import Selector from '../../atoms/Selector'
import TextError from '../../atoms/TextError'
import BoxColorIndicator from '../../molecules/BoxColorIndicator'
import BoxImageInput from '../../molecules/BoxImageInput'
import DualBoxColorIndicator from '../../molecules/DualBoxColorIndicator'
import DrawerTranslation from '../../templates/DrawerTranslation'

import DrawerThemeSettingForm, { ThemeSettingForm } from '../../templates/DrawerThemeSettingForm'
import { FormInputDrawer, FormMode } from '../../types'

import ChevronRightIcon from '../../atoms/Icons/ChevronRightIcon'
import Switcher from '../../atoms/Switcher'
import DrawerImage from '../../templates/DrawerImage'

type InputPropertyForm = Pick<
  ModelProperty,
  'name' | 'image' | 'actionType' | 'formId' | 'translationId' | 'price' | 'default' | 'status'
> &
  // Currency &
  Pick<EnvironmentConfiguration, 'backgroundColor' | 'button' | 'primaryFontColor'> & {
    bgBegin?: string
    bgEnd?: string
  }

export type OutputModelPropertyForm = Omit<
  ModelProperty,
  'modelId' | 'customizationPartId' | 'configures' | 'propertyId'
>

type Props = {
  data?: Nullable<ModelProperty>
  subGroups?: FormGroup[]
  onSubmit?: (v: OutputModelPropertyForm) => void
  onChangeTheme: (params: { bgBeginValue: string; bgEndValue: string; imageValue: string }) => void
}

export type ModelPropertyInfoPanelRef = {
  onSubmit: () => void
  onSubmitForRefresh: () => void
  onReset: () => void
  // getTheme: { bgBeginValue: string; bgEndValue: string; imageValue: string }
}

// eslint-disable-next-line react/display-name
const ModelPropertyInfoPanel = forwardRef<ModelPropertyInfoPanelRef, Props>(
  ({ subGroups = [], data, onSubmit, onChangeTheme }, ref) => {
    const {
      register,
      setValue,
      watch,
      formState: { errors },
      handleSubmit,
      getValues,
      reset,
    } = useForm<InputPropertyForm>()

    const buttonRef = useRef<HTMLButtonElement>(null)

    const [openImageDrawer, setOpenImageDrawer] = useState(false)

    const [isOpenTranslationDrawer, setIsOpenTranslationDrawer] = useState<boolean>(false)

    const statusValue = watch('status')
    const defaultValue = watch('default')

    const imageValue = watch('image')
    const translationIdValue = watch('translationId')
    const bgBeginValue = watch('bgBegin')
    const bgEndValue = watch('bgEnd')
    const backgroundColorValue = watch('backgroundColor')
    const primaryFontColorValue = watch('primaryFontColor')
    const buttonBackgroundColorValue = watch('button.backgroundColor')
    const buttonForegroundColorValue = watch('button.foregroundColor')
    const buttonBorderValue = watch('button.border')

    const [openThemeSettingDrawer, setOpenThemeSettingDrawer] = useState<
      FormInputDrawer<ThemeSettingForm>
    >({
      status: false,
      mode: FormMode.Idle,
    })

    const handleOnSelectImage = useCallback((value: string) => {
      setValue('image', value)
    }, [])

    const handleOnRefSubmit = useCallback(() => {
      console.log('submit')
      buttonRef.current?.click()
    }, [])

    const handleOnRefSubmitForRefresh = useCallback(() => {
      buttonRef.current?.click()
    }, [])

    const handleOnSubmitData = useCallback(
      (values: InputPropertyForm) => {
        const { price, bgBegin, bgEnd, backgroundColor, primaryFontColor, button, ...params } =
          values
        const payload: OutputModelPropertyForm = {
          ...params,
          price: {
            euro: Number(price.euro),
            pound: Number(price.pound),
            usd: Number(price.usd),
            thb: Number(price.thb),
          },
          environmentConfiguration: {
            backgroundCard: [bgBegin || '', bgEnd || ''],
            backgroundColor: backgroundColor,
            primaryFontColor: primaryFontColor,
            button: button || {},
          },
        }
        if (onSubmit) onSubmit(payload)
      },
      [onSubmit],
    )

    const handleOpenTranslationDrawer = useCallback(() => {
      setIsOpenTranslationDrawer(true)
    }, [])

    const handleOnSubmitTranslation = useCallback((val: Translation) => {
      setValue('name', val.name)
      setValue('translationId', val.docId)
    }, [])

    const handleCloseTranslationDrawer = useCallback(() => {
      setIsOpenTranslationDrawer(false)
    }, [])

    const handleOnOpenSettingTheme = useCallback(() => {
      const payload = {
        bgBegin: getValues('bgBegin'),
        bgEnd: getValues('bgEnd'),
        backgroundColor: getValues('backgroundColor'),
        primaryFontColor: getValues('primaryFontColor'),
        button: {
          backgroundColor: getValues('button.backgroundColor'),
          foregroundColor: getValues('button.foregroundColor'),
          border: getValues('button.border'),
        },
      }
      setOpenThemeSettingDrawer({ status: true, mode: FormMode.Update, value: payload })
    }, [])

    const handleOnCloseSetThemeDrawer = useCallback(() => {
      setOpenThemeSettingDrawer({ status: false, mode: FormMode.Idle })
    }, [])

    const handleOnSubmitSettingTheme = useCallback((value: ThemeSettingForm) => {
      setValue('bgBegin', value.bgBegin)
      setValue('bgEnd', value.bgEnd)
      setValue('backgroundColor', value.backgroundColor)
      setValue('primaryFontColor', value.primaryFontColor)
      setValue('button.backgroundColor', value.button?.backgroundColor || '')
      setValue('button.foregroundColor', value.button?.foregroundColor || '')
      setValue('button.border', value.button?.border)
    }, [])

    const handleOnReset = useCallback(() => {
      reset()
    }, [reset])

    const handleOnOpenImageDrawer = useCallback(() => {
      setOpenImageDrawer(true)
    }, [])

    const handleOnCloseImageDrawer = useCallback(() => {
      setOpenImageDrawer(false)
      console.log('on close')
    }, [])

    // const handleOnGetTheme = useCallback(() => {}, [])

    useImperativeHandle(ref, () => ({
      onSubmit: handleOnRefSubmit,
      onSubmitForRefresh: handleOnRefSubmitForRefresh,
      onReset: handleOnReset,
      getTheme: {
        bgBeginValue: bgBeginValue || '',
        bgEndValue: bgEndValue || '',
        imageValue: imageValue || '',
      },
    }))

    useEffect(() => {
      setValue('default', false)
      setValue('status', false)
      console.log('data: ', data)
      if (!data) return

      setValue('name', data?.name)
      setValue('actionType', data?.actionType)
      setValue('formId', data?.formId)
      setValue('price.euro', data?.price.euro)
      setValue('price.pound', data?.price.pound)
      setValue('price.thb', data?.price.thb)
      setValue('price.usd', data?.price.usd)
      setValue('image', data?.image)
      setValue('translationId', data.translationId)

      setValue('default', data?.default || false)
      setValue('status', data.status || false)

      const { environmentConfiguration } = data || {}
      const [begin, end] = environmentConfiguration?.backgroundCard || ['', '']
      setValue('bgBegin', begin)
      setValue('bgEnd', end)

      setValue('backgroundColor', environmentConfiguration?.backgroundColor)
      setValue('primaryFontColor', environmentConfiguration?.primaryFontColor)

      const { backgroundColor, foregroundColor, border } = environmentConfiguration?.button || {}
      setValue('button.backgroundColor', backgroundColor || '')
      setValue('button.foregroundColor', foregroundColor || '')
      setValue('button.border', border)
    }, [data])

    useEffect(() => {
      onChangeTheme({
        bgBeginValue: bgBeginValue || '',
        bgEndValue: bgEndValue || '',
        imageValue: imageValue || '',
      })
    }, [bgBeginValue, bgEndValue, imageValue])

    return (
      <>
        <form onSubmit={handleSubmit(handleOnSubmitData)}>
          <div className='h-full py-5 px-5 flex flex-col gap-5'>
            <div className='flex gap-5 items-start'>
              <FormField label='Property Name'>
                <div className='flex space-x-5'>
                  <div className='flex-1'>
                    <input className='hidden' {...register('translationId')} />
                    <Input
                      {...register('name', {
                        required: {
                          value: true,
                          message: getErrorInputMessage(ErrorInputType.Required, 'Property Name'),
                        },
                      })}
                      className='w-full'
                    />
                    <TextError>{errors?.name?.message}</TextError>
                  </div>
                  <OutlinedButton type='button' onClick={handleOpenTranslationDrawer}>
                    Translation
                  </OutlinedButton>
                </div>
              </FormField>
              <FormField label='Action type'>
                <Selector {...register('actionType', { required: true })} options={actionTypes} />
                <TextError>
                  {errors.actionType && getErrorInputMessage(errors.actionType.type, 'Action type')}
                </TextError>
              </FormField>
              <FormField label='Sub group'>
                <Selector
                  {...register('formId', {
                    required: { value: !!subGroups.length, message: 'Sub group is required' },
                  })}
                  options={[
                    { label: 'none', value: '' },
                    ...subGroups.map(({ name, formId }) => ({ label: name, value: formId })),
                  ]}
                />
                <TextError>{errors.formId?.message}</TextError>
              </FormField>
            </div>
            <div className='flex gap-5 items-start'>
              <FormField label='Price (EUR)'>
                <Input {...register('price.euro', { required: true })} />
                <TextError> {errors?.price?.euro && 'Price (EUR) is required'}</TextError>
              </FormField>
              <FormField label='Price (GBP)'>
                <Input {...register('price.pound', { required: true })} />
                <TextError> {errors?.price?.pound && 'Price (GBP) is required'}</TextError>
              </FormField>
              <FormField label='Price (USD)'>
                <Input {...register('price.usd', { required: true })} />
                <TextError> {errors?.price?.usd && 'Price (USD) is required'}</TextError>
              </FormField>
              <FormField label='Price (THB)'>
                <Input {...register('price.thb', { required: true })} />
                <TextError> {errors?.price?.thb && 'Price (THB) is required'}</TextError>
              </FormField>
            </div>
            <div className='flex items-start gap-5'>
              <div className='flex-2 flex flex-col items-stretch space-y-5'>
                <p className=' font-bold text-secondary-font-color'>Theme Setting</p>
                <div className='hidden'>
                  <input {...register('bgBegin')} />
                  <input {...register('bgEnd')} />
                  <input {...register('backgroundColor')} />
                  <input {...register('primaryFontColor')} />
                  <input {...register('button')} />
                </div>
                <div className='flex rounded-md px-3 py-5 border'>
                  <div className='flex-1'>
                    <DualBoxColorIndicator
                      colors={[bgBeginValue || '', bgEndValue || '']}
                      name='Panel Background Gradient Color'
                    />
                  </div>
                  <div className='flex-1'>
                    <BoxColorIndicator color={backgroundColorValue} name='Part Background Color' />
                  </div>
                  <div className='flex-1'>
                    <BoxColorIndicator color={primaryFontColorValue} name='Text Color' />
                  </div>
                  <div className='flex-1'>
                    <BoxColorIndicator
                      color={buttonBackgroundColorValue}
                      name='Button Background Color'
                    />
                  </div>
                  <div className='flex-1'>
                    <BoxColorIndicator
                      color={buttonForegroundColorValue}
                      name='Button Foreground Color'
                    />
                  </div>
                  <div className='flex-1'>
                    <BoxColorIndicator color={buttonBorderValue} name='Button Border Color' />
                  </div>
                </div>
                <div>
                  <OutlinedButton type='button' onClick={handleOnOpenSettingTheme}>
                    <div className='flex items-center space-x-1'>
                      <span>Set Theme</span>
                      <span>
                        <ChevronRightIcon className='w-5 h-5' />
                      </span>
                    </div>
                  </OutlinedButton>
                </div>
              </div>

              <div className='flex-1 flex flex-col space-y-5'>
                <p className=' font-bold text-secondary-font-color'>Image</p>
                <div className='flex  space-x-4'>
                  <div className='flex flex-col'>
                    <BoxImageInput
                      src={imageValue}
                      onSelect={handleOnOpenImageDrawer}
                      onDelete={() => setValue('image', '')}
                    />
                    <TextError>{errors.image && errors.image.message}</TextError>
                    <input
                      className='hidden'
                      {...register('image', {
                        required: {
                          value: true,
                          message: getErrorInputMessage(ErrorInputType.Required, 'Image'),
                        },
                      })}
                    />
                  </div>
                  <div className='flex flex-col items-center space-y-5'>
                    <div className='flex space-x-2 items-center'>
                      <div className='w-28'>
                        <p>Set as default</p>
                      </div>
                      <Switcher enabled={defaultValue} onChange={(v) => setValue('default', v)} />
                      <input className='hidden' {...register('default')} />
                    </div>
                    <div className='flex space-x-2 items-center'>
                      <div className=' w-28'>
                        <p>Set as Show</p>
                      </div>
                      <Switcher enabled={statusValue} onChange={(v) => setValue('status', v)} />
                      <input className='hidden' {...register('status')} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type='submit' ref={buttonRef} className='hidden'></button>
        </form>
        <DrawerTranslation
          isOpen={isOpenTranslationDrawer}
          translationId={translationIdValue}
          onSubmit={handleOnSubmitTranslation}
          onClose={handleCloseTranslationDrawer}
        />
        <DrawerThemeSettingForm
          isOpen={openThemeSettingDrawer.status}
          value={openThemeSettingDrawer?.value}
          mode={openThemeSettingDrawer?.mode}
          onSubmit={handleOnSubmitSettingTheme}
          onClose={handleOnCloseSetThemeDrawer}
        />
        <DrawerImage
          isOpen={openImageDrawer}
          onClose={handleOnCloseImageDrawer}
          onSubmit={handleOnSelectImage}
        />
      </>
    )
  },
)

export default memo(ModelPropertyInfoPanel)
