/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FlatButton from '../../components/atoms/Button/FlatButton'
import OutlinedButton from '../../components/atoms/Button/OutlinedButton'
import Card from '../../components/atoms/Card'
import FormField from '../../components/atoms/FormField'
import EngFlagIcon from '../../components/atoms/Icons/Flags/EngFlagIcon'
import FrenchFlagIcon from '../../components/atoms/Icons/Flags/FrenchFlagIcon'
import ItalianFlagIcon from '../../components/atoms/Icons/Flags/ItalianFlagIcon'
import SpanishFlagIcon from '../../components/atoms/Icons/Flags/SpanishFlagIcon'
import Input from '../../components/atoms/Input'
import InputImageGallery from '../../components/atoms/InputImageGallery'
import ProductSettingFormAccordion from '../../components/molecules/ProductSettingFormAccordion'
import RootMainTemplate from '../../components/templates/RootMainTemplate'
import StateTemplate from '../../components/templates/StateTemplate'
import StateTemplateV2 from '../../components/templates/StateTemplateV2'
import { useAppDispatch, useAppSelector } from '../../modules/hook'
import {
  deleteCustomizationPart,
  fetchModelCustomizationPartsList,
  updateCustomizationPartOrders,
  updateCustomizationPartStatus,
} from '../../modules/stores/customizationParts/action'
import {
  createModelData,
  fetchCurrentModel,
  fetchModelsList,
  updateModelData,
} from '../../modules/stores/models/actions'
import { StateData } from '../../modules/types/module'
import { useForm, SubmitHandler } from 'react-hook-form'
import {
  CreateModelData,
  Environment,
  ExtendPart,
  Model,
  Price,
  UpdateModelData,
} from '../../modules/types/models'
import { LanguageMap } from '../../modules/types/translation'
import DndPartExtensionList from '../../components/organisms/DndPartExtensionList'
import DndModelCustomizationParts from '../../components/organisms/DndModelCustomizationParts'
import { CustomizationPart } from '../../modules/types/customizationPart'
import DrawerTranslation from '../../components/templates/DrawerTranslation'
import { FormInputDrawer, FormMode } from '../../components/types'
import DrawerExtensionPartForm, {
  InputFormExtensionPart,
} from '../../components/templates/DrawerExtensionPartForm'
import DrawerCustomizationPartForm from '../../components/templates/DrawerCustomizationPartForm'
import TextError from '../../components/atoms/TextError'
import LoadingDialog from '../../components/molecules/LoadingDialog'
import { resetCurrentModel } from '../../modules/stores/models'
import useToastMessage from '../../hooks/useToastMessage.hook'
import useConfirmDelete from '../../hooks/useConfirmDelete.hook'
import ConfirmDeleteModal from '../../components/molecules/ConfirmDeleteModal'
import DrawerImage from '../../components/templates/DrawerImage'
import DndTitleCaption from '../../components/molecules/DndTitleCaption'

type ModelForm = {
  name: string
  image: string
  sketchFabModelUid: string
  environment: Environment
  price: Price
  modelDescription: LanguageMap
  productDescription: LanguageMap
}

const ProductSettingInformation: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { modelId } = useParams()

  const navigator = useNavigate()
  const { showSuccess, showError } = useToastMessage()

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<ModelForm>()

  const buttonSubmitRef = useRef<HTMLButtonElement>(null)

  const [isOpenTranslationDrawer, setIsOpenTranslationDrawer] = useState<boolean>(false)
  const [isOpenImageGalleryDrawer, setIsOpenImageGalleryDrawer] = useState<boolean>(false)
  // const [isOpenOnAddCustomizationPart, setIsOpenAddCustomizationPart] = useState<boolean>(false)

  const [isOpenLoadingDialog, setIsOpenLoadingDialog] = useState<boolean>(false)
  const { openConfirmDelete, onOpenDeleteModal, onCloseDeleteModal } = useConfirmDelete()
  const [openExtensionPartDrawer, setOpenExtensionPartDrawer] = useState<
    FormInputDrawer<{ index: number; data: ExtendPart }>
  >({
    status: false,
    mode: FormMode.Idle,
  })

  const [openCustomizationPartDrawer, setOpenCustomizationPartDrawer] = useState<
    FormInputDrawer<string>
  >({
    status: false,
    mode: FormMode.Idle,
  })

  const [parts, setParts] = useState<ExtendPart[]>([])
  const [customizationParts, setCustomizationParts] = useState<CustomizationPart[]>([])

  const { currentModel, modelCustomizationParts } = useAppSelector((state) => ({
    currentModel: state.model.currentModel,
    modelCustomizationParts: state.customizationParts.modelCustomizationParts,
  }))

  const imageValue = watch('image')

  const onSubmit: SubmitHandler<ModelForm> = async (data) => {
    const { modelDescription, productDescription, price, ...params } = data

    const payload: CreateModelData = {
      ...params,
      extendDemoPartInformation: {
        noteDetail: productDescription,
        description: {
          name: modelDescription,
        },
        parts: parts,
      },
      price: {
        pound: Number(price.pound || 0),
        usd: Number(price.usd || 0),
        euro: Number(price.euro || 0),
        thb: Number(price.thb || 0),
      },
    }

    if (modelId) {
      onAsyncUpdateData({ modelId, ...payload })
    } else {
      onAsyncCreateData(payload)
    }
  }

  const onAsyncUpdateData = async (data: UpdateModelData) => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(updateModelData(data)).unwrap()
      showSuccess('Update Model Successfully')
      dispatch(fetchModelsList())
      navigate('../product-settings')
    } catch (error) {
      showError('Update Model Failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }

  const onAsyncCreateData = async (data: CreateModelData) => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(createModelData(data)).unwrap()
      showSuccess('Create Model Successfully')

      dispatch(fetchModelsList())
      navigate('../product-settings')
    } catch (error) {
      showError('Create Model Successfully')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }

  const handleOpenTranslationDrawer = useCallback(() => {
    setIsOpenTranslationDrawer(true)
  }, [])

  const handleCloseTranslationDrawer = useCallback(() => {
    setIsOpenTranslationDrawer(false)
  }, [])

  const handleOnOpenImageGallery = useCallback(() => {
    setIsOpenImageGalleryDrawer(true)
  }, [])

  const handleOnCloseImageGallery = useCallback(() => {
    setIsOpenImageGalleryDrawer(false)
  }, [])

  // Extension Part //
  const handleOnAddOpenPartDrawer = useCallback(() => {
    setOpenExtensionPartDrawer({ status: true, mode: FormMode.Add })
  }, [])

  const handleOnEditOpenPartDrawer = useCallback((index: number, value: ExtendPart) => {
    setOpenExtensionPartDrawer({
      status: true,
      mode: FormMode.Update,
      value: { index, data: value },
    })
  }, [])

  const handleOnCloseExtensionPartDrawer = useCallback(() => {
    setOpenExtensionPartDrawer({ status: false, mode: FormMode.Idle })
  }, [])

  const handleOnChangeOrderExtensionParts = useCallback((items: ExtendPart[]) => {
    setParts(items.map((item, index) => ({ ...item, order: index + 1 })))
  }, [])

  const handleOnDeleteExtensionPart = useCallback((index: number) => {
    setParts((prev) => {
      prev.splice(index, 1)
      return prev.length ? [...prev] : []
    })
  }, [])

  const handleOnAddExtensionPart = useCallback((value: InputFormExtensionPart) => {
    setParts((prev) => [...prev, { ...value, order: prev.length + 1 }])
  }, [])

  const handleOnUpdateExtensionPart = useCallback((index: number, value: ExtendPart) => {
    setParts((prev) => {
      prev[index] = value
      return [...prev]
    })
  }, [])

  //  =================================================================== ///

  const handleOnChangeOrderCustomizationParts = useCallback(async (items: CustomizationPart[]) => {
    try {
      setIsOpenLoadingDialog(true)

      const payload = items.map(({ partId, order }) => ({ partId, order }))
      await dispatch(updateCustomizationPartOrders(payload)).unwrap()
      showSuccess('Update customization order successfully')
    } catch (error) {
      showError('Update customization order failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const handleOnChangeCustomizationPartStatus = useCallback(
    async (partId: string, active: boolean) => {
      try {
        setIsOpenLoadingDialog(true)
        await dispatch(updateCustomizationPartStatus({ partId, active })).unwrap()
        showSuccess('Update customization status successfully')
      } catch (error) {
        showError('Update customization status failed')
      } finally {
        setIsOpenLoadingDialog(false)
      }
    },
    [],
  )

  const handleOnAddCustomizationPart = useCallback(() => {
    setOpenCustomizationPartDrawer({ status: true, mode: FormMode.Add })
  }, [])

  const handleOnEditCustomizationPart = useCallback((value: CustomizationPart) => {
    setOpenCustomizationPartDrawer({ status: true, mode: FormMode.Update, value: value.partId })
  }, [])

  const handleOnCloseCustomizationPartDrawer = useCallback(() => {
    setOpenCustomizationPartDrawer({ status: false, mode: FormMode.Idle })
  }, [])

  const stateFetching = useMemo(() => {
    if (
      currentModel.state === StateData.Success &&
      modelCustomizationParts.state === StateData.Success
    )
      return StateData.Success
    if (
      currentModel.state === StateData.Loading ||
      modelCustomizationParts.state === StateData.Loading
    )
      return StateData.Loading
    if (
      currentModel.state === StateData.Failed ||
      modelCustomizationParts.state === StateData.Failed
    )
      return StateData.Failed

    return StateData.Idle
  }, [currentModel.state, modelCustomizationParts.state])

  const handleOnClickSave = useCallback(() => {
    buttonSubmitRef.current?.click()
  }, [])

  const handleOnDeleteCustomizationPart = useCallback(async (id: string) => {
    try {
      setIsOpenLoadingDialog(true)
      await dispatch(deleteCustomizationPart(id)).unwrap()
      showSuccess('Delete Success')
    } catch (error) {
      showError('Delete Failed')
    } finally {
      setIsOpenLoadingDialog(false)
    }
  }, [])

  const handleOnSubmitImageDrawer = useCallback((val: string) => {
    setValue('image', val)
  }, [])

  const modeForm = useMemo(() => (modelId ? FormMode.Update : FormMode.Add), [modelId])

  const isLoadingState = useMemo(() => {
    return modeForm === FormMode.Update && currentModel.state === StateData.Loading
  }, [modeForm, currentModel.state])

  const isSuccessState = useMemo(() => {
    return (
      modeForm === FormMode.Add ||
      (currentModel.state === StateData.Success && modeForm === FormMode.Update)
    )
  }, [modeForm, currentModel.state])

  useEffect(() => {
    if (!modelId) return

    if (currentModel.state === StateData.Idle || currentModel.data?.modelId !== modelId) {
      const id = String(modelId)
      dispatch(fetchCurrentModel(id))
      dispatch(fetchModelCustomizationPartsList(id))
    }
  }, [modelId, currentModel.state, currentModel.data?.modelId])

  useEffect(() => {
    if (currentModel.state === StateData.Success) {
      const { data } = currentModel
      console.log(data)
      setValue('name', data?.name || '')
      setValue('sketchFabModelUid', data?.sketchFabModelUid || '')
      setValue('image', data?.image || '')

      // Environment
      setValue('environment.backgroundScene', data?.environment?.backgroundScene || '')
      setValue('environment.foreground', data?.environment?.foreground || '')

      // Price
      setValue('price.euro', data?.price?.euro || 0)
      setValue('price.usd', data?.price?.usd || 0)
      setValue('price.pound', data?.price?.pound || 0)
      setValue('price.thb', data?.price?.thb || 0)

      // Model Description
      setValue('modelDescription.en', data?.extendDemoPartInformation?.description?.name.en || '')
      setValue('modelDescription.es', data?.extendDemoPartInformation?.description?.name.es || '')
      setValue('modelDescription.fr', data?.extendDemoPartInformation?.description?.name.fr || '')
      setValue('modelDescription.it', data?.extendDemoPartInformation?.description?.name.it || '')

      // Product Description
      setValue('productDescription.en', data?.extendDemoPartInformation?.noteDetail?.en || '')
      setValue('productDescription.es', data?.extendDemoPartInformation?.noteDetail?.es || '')
      setValue('productDescription.fr', data?.extendDemoPartInformation?.noteDetail?.fr || '')
      setValue('productDescription.it', data?.extendDemoPartInformation?.noteDetail?.it || '')

      setParts(
        data?.extendDemoPartInformation?.parts?.map((part, index) => ({ ...part, index })) || [],
      )
    }
  }, [currentModel])

  useEffect(() => {
    return () => {
      dispatch(resetCurrentModel())
    }
  }, [])

  return (
    <Fragment>
      <RootMainTemplate
        title='Model Information'
        onSave={handleOnClickSave}
        onClose={() => navigator('../product-settings')}
      >
        <StateTemplateV2 isLoading={isLoadingState} isSuccess={isSuccessState}>
          <div className='container mx-auto flex flex-col space-y-5 pt-5 pb-20'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card className='flex flex-col space-y-5 px-5 py-5'>
                <p className='text-xl'>Model Setting</p>
                <div className='flex space-x-5 items-start'>
                  <FormField label='Model Name'>
                    <Input {...register('name', { required: true })} className='w-full' />
                    <TextError>{errors.name ? 'name is required.' : ''}</TextError>
                  </FormField>
                  <FormField label='SketchFab Model ID'>
                    <Input {...register('sketchFabModelUid', { required: true })} />
                    <TextError>
                      {errors?.sketchFabModelUid ? 'SketchFab Model ID is required.' : ''}
                    </TextError>
                  </FormField>
                </div>
                <div className='flex space-x-5 items-start'>
                  <FormField label='Background Colour'>
                    <div className='flex space-x-1'>
                      <Input {...register('environment.backgroundScene')} className='w-full' />
                    </div>
                  </FormField>
                  <FormField label='Foreground Colour'>
                    <div className='flex space-x-1'>
                      <Input {...register('environment.foreground')} className='w-full' />
                    </div>
                  </FormField>
                  <FormField label='Image'>
                    <InputImageGallery
                      className='w-full'
                      {...register('image')}
                      onClick={handleOnOpenImageGallery}
                    />
                  </FormField>
                </div>
                <div className='flex flex-col'>
                  <ProductSettingFormAccordion title='Product Price'>
                    <div className='flex space-x-5 items-start'>
                      <div className='flex-1'>
                        <FormField label='Price (USD)'>
                          <Input {...register('price.usd', { required: true })} type='number' />
                          <TextError>{errors.price?.usd ? 'USD Price is required.' : ''}</TextError>
                        </FormField>
                      </div>
                      <div className='flex-1'>
                        <FormField label='Price (EUR)'>
                          <Input {...register('price.euro', { required: true })} type='number' />
                          <TextError>
                            {errors.price?.euro ? 'EURO Price is required.' : ''}
                          </TextError>
                        </FormField>
                      </div>
                      <div className='flex-1'>
                        <FormField label='Price (GBP)'>
                          <Input {...register('price.pound', { required: true })} type='number' />
                          <TextError>
                            {errors.price?.pound ? 'Pound Price is required.' : ''}
                          </TextError>
                        </FormField>
                      </div>
                      <div className='flex-1'>
                        <FormField label='Price (THB)'>
                          <Input {...register('price.thb', { required: true })} type='number' />
                          <TextError>
                            {errors.price?.pound ? 'Thai Price is required.' : ''}
                          </TextError>
                        </FormField>
                      </div>
                    </div>
                  </ProductSettingFormAccordion>
                  <ProductSettingFormAccordion title='Model Description'>
                    <div className='flex flex-col items-stretch space-y-5'>
                      <FormField prefix={<EngFlagIcon />} label='English Model Description'>
                        <Input {...register('modelDescription.en')} />
                      </FormField>
                      <FormField prefix={<FrenchFlagIcon />} label='French Model Description'>
                        <Input {...register('modelDescription.fr')} />
                      </FormField>
                      <FormField prefix={<SpanishFlagIcon />} label='Spanish Model Description'>
                        <Input {...register('modelDescription.es')} />
                      </FormField>
                      <FormField prefix={<ItalianFlagIcon />} label='Italian Model Description'>
                        <Input {...register('modelDescription.it')} />
                      </FormField>
                    </div>
                  </ProductSettingFormAccordion>
                  <ProductSettingFormAccordion title='Product Description'>
                    <div className='flex flex-col items-stretch space-y-5'>
                      <FormField prefix={<EngFlagIcon />} label='English Product Description'>
                        <Input {...register('productDescription.en')} />
                      </FormField>
                      <FormField prefix={<FrenchFlagIcon />} label='French Product Description'>
                        <Input {...register('productDescription.fr')} />
                      </FormField>
                      <FormField prefix={<SpanishFlagIcon />} label='Spanish Product Description'>
                        <Input {...register('productDescription.es')} />
                      </FormField>
                      <FormField prefix={<ItalianFlagIcon />} label='Italian Product Description'>
                        <Input {...register('productDescription.it')} />
                      </FormField>
                    </div>
                  </ProductSettingFormAccordion>
                </div>
              </Card>
              <button type='submit' className='hidden' ref={buttonSubmitRef}></button>
            </form>
            <Card className='p-5'>
              <div className='flex flex-col items-stretch space-y-4 w-full'>
                <div>
                  <div className='flex item-center justify-between'>
                    <p className='py-5 text-xl'>Parts</p>
                    <FlatButton type='button' onClick={handleOnAddOpenPartDrawer}>
                      Add
                    </FlatButton>
                  </div>
                  <DndTitleCaption />
                </div>
                <DndPartExtensionList
                  extensionParts={parts}
                  onChangeOrder={handleOnChangeOrderExtensionParts}
                  onEdit={handleOnEditOpenPartDrawer}
                  onDelete={handleOnDeleteExtensionPart}
                />
              </div>
            </Card>
            {modeForm === FormMode.Update && (
              <Card className='p-5'>
                <div className='flex justify-between items-center'>
                  <p className='py-5 text-xl'>Customization Parts</p>
                  <FlatButton type='button' onClick={handleOnAddCustomizationPart}>
                    Add
                  </FlatButton>
                </div>
                <DndModelCustomizationParts
                  customizationParts={modelCustomizationParts.data}
                  onChangeStatus={handleOnChangeCustomizationPartStatus}
                  onChangeOrder={handleOnChangeOrderCustomizationParts}
                  onEdit={handleOnEditCustomizationPart}
                  onDelete={onOpenDeleteModal}
                />
              </Card>
            )}
          </div>
        </StateTemplateV2>
      </RootMainTemplate>

      <DrawerTranslation isOpen={isOpenTranslationDrawer} onClose={handleCloseTranslationDrawer} />
      <DrawerExtensionPartForm
        initial={openExtensionPartDrawer.value}
        isOpen={openExtensionPartDrawer.status}
        mode={openExtensionPartDrawer.mode}
        onClose={handleOnCloseExtensionPartDrawer}
        onAdd={handleOnAddExtensionPart}
        onUpdate={handleOnUpdateExtensionPart}
      />
      <DrawerCustomizationPartForm
        isOpen={openCustomizationPartDrawer.status}
        mode={openCustomizationPartDrawer.mode}
        modelId={modelId || ''}
        customizationPartId={openCustomizationPartDrawer.value}
        onClose={handleOnCloseCustomizationPartDrawer}
      />
      <ConfirmDeleteModal
        isOpen={openConfirmDelete.status}
        value={openConfirmDelete?.value}
        onClose={onCloseDeleteModal}
        onConfirm={handleOnDeleteCustomizationPart}
      />
      <DrawerImage
        isOpen={isOpenImageGalleryDrawer}
        value={imageValue}
        onClose={handleOnCloseImageGallery}
        onSubmit={handleOnSubmitImageDrawer}
      />
      <LoadingDialog isOpen={isOpenLoadingDialog} onClose={() => setIsOpenLoadingDialog(false)} />
    </Fragment>
  )
}

export default ProductSettingInformation
