

export enum ConfigurationType {
    Color = 'Color',
    Texture = 'Texture',
    Visibility = 'Visibility', // to
    Glossiness = 'Glossiness',
    Roughness = 'Roughness',
    Metalness = 'Metalness'
}

export enum PerformType {
    active = 'active',
    inactive = 'inactive'
}

export type Configuration = {
    type: ConfigurationType,
    materialName: string,
    performType: PerformType,
    color?: string,
    texture?: Record<string, string | number | boolean>,
    visible?: boolean | number,
    glossiness?: number,
    roughness?: number,
    metalness?: number
}

export type ConfigurationItem = Configuration & { index: number }

export enum ActionType {
    selector = 'selector',
    toggle = 'toggle',
    multipleSelector = 'multipleSelector'
}

export type EnvironmentConfiguration = {
    backgroundColor?: string,
    backgroundCard?: string[],
    primaryFontColor?: string,
    button?: {
        backgroundColor?: string,
        foregroundColor?: string,
        border?: string
    }
}

export type Currency = {
    pound: number,
    usd: number,
    euro: number,
    thb: number,
}


export type ModelProperty = {
    modelId: string,
    default: boolean,
    propertyId: string,
    actionType: ActionType,
    translationId?: string,
    name: string,
    formId?: string,
    price: Currency
    image?: string,
    customizationPartId: string,
    configures: Configuration[],
    environmentConfiguration?: EnvironmentConfiguration,
    order?: number
    status: boolean
}

export type CreateModelProperty = Omit<ModelProperty, 'order' | 'propertyId'>
export type UpdateModelProperty = Omit<ModelProperty, 'order'>
export type UpdateModelPropertyOrder = Pick<ModelProperty, 'propertyId' | 'order'>
export type UpdateModelPropertyStatus = Pick<ModelProperty, 'propertyId' | 'status'>