/* eslint-disable camelcase */

interface ApplicationEnv {
    mode: string;
    zapp_model_url: string;
    zapp_crm_font_url: string;// not used
    zapp_auth_url: string;
}

const env: ApplicationEnv = {
    mode: process.env.REACT_APP_ENV || 'local',
    zapp_model_url: process.env.REACT_APP_ZAPP_MODEL_URL || '',
    zapp_crm_font_url: process.env.REACT_APP_CRM_FONT_URL || '',// not used
    zapp_auth_url: process.env.REACT_APP_ZAPP_AUTH_URL || '',
}


export default env